<template>
    <div class="HotContainer">
      <a-spin :spinning="spinning">
        <div class="header">
          <span>
            关键字 <a-input style="width: 78%;margin-left: 5px" placeholder="请输入" v-model="searchList.keyword"/>
          </span>
          <span style="margin-left: 40px">
            <a-button style="margin-left: 15px" type="primary" @click="searchBtn">查询</a-button>
            <a-button style="margin-left: 10px" @click="resetBtn">重置</a-button>
          </span>
        </div>

      <div class="hotTable">
        <a-table
            :columns="tableColumns"
            :data-source="tableData"
            :pagination="false"
            :row-key="(record) => record.id"
        >
        </a-table>
          <a-pagination style="float: right;margin-top: 20px" :current="current" :total="total" @showSizeChange="onShowSizeChange" @change="onShowSizeChange"  show-quick-jumper show-size-changer/>
      </div>
      </a-spin>
    </div>
</template>
<script>
import {
  getHotSearch,
} from "@/service/systemApi"
export default {
  name: 'HotSearchWords',
  data () {
    return{
      spinning: false,
      // 搜索区域绑定对象
      searchList:{
        keyword: '' // 关键字
      },
      searchValue: '',
      current: 1,
      total: 1,
      pageSize: 10,
      tableColumns:[
        {
          title: '关键字',
          dataIndex: 'keyword',
          width: '50%'
        },
        {
          title: '搜索次数',
          dataIndex: 'frequency',
          width: '50%'
        },
      ],
      tableData: [],
    }

  },
  created() {
    this.getTableData()
  },
  methods: {
    resetBtn () {
      this.searchList = {} // 重置搜索框
      this.searchValue = ''
      this.getTableData()
    },
    async onShowSizeChange(current, pageSize,data) { // 分页器中点击页码或者调整显示数据多少时触发
      this.spinning = true
      if (data === undefined) data = ''
      this.current = current // 将当前页码绑定给分页器
      this.pageSize = pageSize // 一页的数值长度
      const response = await getHotSearch(current,pageSize,this.searchValue);
      if (response.code === 0) {
        this.tableData = response.data
        this.total = response.count
        this.spinning = false
      }else {
        this.$message.warning(response.message)
      }
    },
    async searchBtn () { // 搜索按钮
      this.searchValue = this.searchList.keyword
      await this.getTableData(this.searchValue)
    },
    async getTableData (data) { // 获取 table 列表
      this.spinning = true
      if (data === undefined) data = ''
      const response = await getHotSearch(this.current,this.pageSize,data);
      if (response.code === 0) {
        this.tableData = response.data
        this.total = response.count
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    }
  }
}

</script>

<style scoped lang="scss">
.HotContainer {
  margin: 0 -15px 0 -15px;
  .header {
    display: flex;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: 15px solid rgba(240, 242, 245, 1);
  }
  .hotTable {
    padding: 20px;
  }
}

</style>